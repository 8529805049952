<template>
  <div class="suppliers">
    <suppliersHero />
    <suppliersCooperation />
    <suppliersPartners />
    <suppliersRequirements />
    <suppliersDocuments />
    <partnersReviews />
    <!-- <suppliersBanner/> -->
  </div>
</template>

<script>
export default {
  name: "Suppliers",
  components: {
    SuppliersHero: () =>
      import("@/components/pages/suppliers/SuppliersHero.vue"),
    SuppliersCooperation: () =>
      import("@/components/pages/suppliers/SuppliersCooperation.vue"),
    SuppliersPartners: () =>
      import("@/components/pages/suppliers/SuppliersPartners.vue"),
    SuppliersRequirements: () =>
      import("@/components/pages/suppliers/SuppliersRequirements.vue"),
    SuppliersDocuments: () =>
      import("@/components/pages/suppliers/SuppliersDocuments.vue"),
    PartnersReviews: () => import("@/components/common/PartnersReviews.vue"),
    // SuppliersBanner: () =>
    //   import("@/components/pages/suppliers/SuppliersBanner.vue"),
  },
};
</script>